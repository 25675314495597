<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;" @click="init" class="cur-a">班级列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div
          class="
            operationControl
            flexdc
            operationControlCurr
            operationControlWidth
          "
          style="align-items: flex-start"
        >
          <div class="searchbox" style="width: 100%">
            <div>
              <div title="班级编码" class="searchboxItem searchboxItems">
                <span class="itemLabel">班级编码:</span>
                <el-input
                  v-model="projectCode"
                  type="text"
                  size="small"
                  placeholder="请输入班级编码 "
                  onkeyup="value=value.replace(/[^\w\.\/]/ig,'')"
                  clearable
                />
              </div>
              <div title="班级名称" class="searchboxItem searchboxItems">
                <span class="itemLabel">班级名称:</span>
                <el-input
                  v-model="projectName"
                  type="text"
                  size="small"
                  placeholder="请输入班级名称"
                  clearable
                  style="flex: 1"
                />
              </div>
              <div class="df">
                <transition name="el-zoom-in-center">
                  <el-button
                    type="primary"
                    class="bgc-bv"
                    round
                    @click="
                      () => {
                        getData();
                      }
                    "
                    v-if="flag == false"
                    >查询</el-button
                  >
                </transition>
              </div>
              <div style="margin-left: 10px">
                <el-button @click="search()" size="small" type="text">
                  高级搜索
                  <i :class="flag ? 'el-icon-top' : 'el-icon-bottom'"></i>
                </el-button>
              </div>
            </div>
            <div class="btnBox">
              <el-button
                type="primary"
                class="bgc-bv"
                round
                @click="addTestPaper"
                >新建班级</el-button
              >
              <el-button class="bgc-bv" round @click="open" size="small"
                >自定义表头</el-button
              >
            </div>
          </div>
          <el-collapse-transition>
            <div>
              <div
                class="searchbox"
                style="margin-top: 10px; width: 100%"
                v-show="flag == true"
              >
                <div class="searchbox">
                  <div title="负责人" class="searchboxItem">
                    <span class="itemLabel">负责人:</span>
                    <el-input
                      v-model="ruleForm.projectUser"
                      type="text"
                      size="small"
                      placeholder="请输入负责人"
                      clearable
                    />
                  </div>
                  <div title="班级状态" class="searchboxItem">
                    <span class="itemLabel">班级状态:</span>
                    <el-select
                      size="small"
                      v-model="ruleForm.Classstatus"
                      placeholder="请选择班级状态"
                      clearable
                    >
                      <el-option
                        v-for="item in ClassstatusList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </div>
                  <div title="行政区划" class="searchboxItem">
                    <span class="itemLabel">行政区划:</span>
                    <el-cascader
                      clearable
                      filterable
                      v-model="ruleForm.areaId"
                      :options="areatreeList"
                      :props="propsarea"
                      size="small"
                    ></el-cascader>
                  </div>
                  <div title="负责人电话" class="searchboxItem">
                    <span class="itemLabel projectUser">负责人电话:</span>
                    <el-input
                      v-model="ruleForm.projectUserPhone"
                      type="text"
                      size="small"
                      placeholder="请输入负责人电话"
                      clearable
                    />
                  </div>
                </div>
              </div>
            </div>
          </el-collapse-transition>
          <el-collapse-transition>
            <div
              class="searchbox"
              style="margin-top: 10px; width: 100%"
              v-show="flag == true"
            >
              <div class="searchbox">
                <div title="培训类型" class="searchboxItem">
                  <span class="itemLabel">培训类型:</span>
                  <tree
                    ref="tree"
                    @eventBtn="childBack"
                    :ruleForm="ruleForm"
                    size="small"
                    @clearParams="clearParams"
                    typeStu
                  />
                </div>
                <div title="开班时间" class="searchboxItem ci-full">
                  <span class="itemLabel">开班时间:</span>
                  <el-date-picker
                    v-model="ruleForm.opentime"
                    type="daterange"
                    size="small"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="timestamp"
                  />
                </div>
                <div title="结束时间" class="searchboxItem ci-full">
                  <span class="itemLabel">结束时间:</span>
                  <el-date-picker
                    clearable
                    size="small"
                    v-model="ruleForm.endTime"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="timestamp"
                  ></el-date-picker>
                </div>
                <div>
                  <el-button
                    type="primary"
                    class="bgc-bv"
                    round
                    @click="
                      () => {
                        getData();
                      }
                    "
                    >查询</el-button
                  >
                </div>
                <div style="margin-left: 10px">
                  <el-button type="text" round @click="resetForm"
                    >清空搜索条件</el-button
                  >
                </div>
              </div>
            </div>
          </el-collapse-transition>
        </div>
        <div style="margin: 15px 0 5px">
          <span>班级总数：{{ projectNum }}</span>
          <span style="margin: 0 30px">班级总人次：{{ projectPeopleNum }}</span>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
              v-if="showtable"
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                fixed
                :index="indexMethod"
              />
              <el-table-column
                label="班级编码"
                align="left"
                show-overflow-tooltip
                prop="projectCode"
                min-width="200px"
                fixed="left"
              />
              <el-table-column
                label="班级名称"
                align="left"
                show-overflow-tooltip
                prop="projectName"
                min-width="250px"
                fixed="left"
              />
              <template v-if="tableHaed.length">
                <el-table-column
                  v-for="(el, index) in tableHaed"
                  :key="index"
                  :label="el.label"
                  :align="el.textAlign"
                  show-overflow-tooltip
                  :min-width="el.width"
                >
                  <template slot-scope="scope">{{
                    getValue(el, scope.row)
                  }}</template>
                </el-table-column>
              </template>
              <el-table-column
                label="操作"
                align="left"
                width="330px"
                fixed="right"
              >
                <template slot-scope="scope">
                  <div>
                    <el-button
                      type="text"
                      size="mini"
                      style="padding: 0 5px"
                      @click="seeDetails(scope.row)"
                      >详情</el-button
                    >
                    <el-button
                      type="text"
                      size="mini"
                      style="padding: 0 5px"
                      @click="jumpclassList(scope.row)"
                      >课程列表</el-button
                    >
                    <el-button
                      type="text"
                      size="mini"
                      style="padding: 0 5px"
                      @click="jumpStudentList(scope.row)"
                      >学员列表</el-button
                    >
                    <!-- <el-button
                      type="text"
                      style="padding: 0 5px"
                      @click="
                        payOrder(scope.row.projectId, scope.row.projectName)
                      "
                      :disabled="
                        scope.row.courseCount == 0 ||
                          scope.row.projectSource == '20' ||
                          scope.row.projectSource == '60'
                      "
                      size="mini"
                      >班级二维码</el-button
                    > -->
                    <!-- <el-button
                      type="text"
                      style="padding: 0 5px"
                      @click="
                        extensionApplication(
                          scope.row
                        )
                      "
                      size="mini"
                      >延期申请</el-button
                    > -->
                    <el-button
                      type="text"
                      size="mini"
                      style="padding: 0 5px"
                      @click="deleteproject(scope.row.projectId)"
                      :disabled="
                        scope.row.auditState == '20' ||
                        scope.row.auditState == '25' ||
                        scope.row.auditState == '30' ||
                        scope.row.projectSource == '20'
                      "
                      >删除</el-button
                    >
                    <el-button
                      type="text"
                      size="mini"
                      style="padding: 0 5px"
                      @click="
                        keeprecord(
                          scope.row.projectId,
                          scope.row.projectSource,
                          scope.row.courseCount,
                          scope.row.completePaper,
                          scope.row
                        )
                      "
                      :disabled="
                        scope.row.auditState == '20' ||
                        scope.row.auditState == '25' ||
                        scope.row.auditState == '30'
                      "
                      >开班申请</el-button
                    >
                  </div>
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>

    <el-dialog
      title="自定义表头"
      :visible.sync="tableHeadDialog"
      width="30%"
      class="custom"
      @close="cencal"
    >
      <p style="padding-left: 20px; margin-bottom: 10px">
        勾选需要显示在列表上的项，可拖动调整顺序
      </p>
      <div>
        <el-tree
          ref="headTree"
          :data="treeDataCopy"
          default-expand-all
          draggable
          :allow-drop="allowDrop"
          :allow-drag="allowDrag"
        >
          <template slot-scope="{ node, data }">
            <el-checkbox
              @change="
                (val) => {
                  handleCheckChange(val, data);
                }
              "
              v-model="data.checked"
              :disabled="data.label == '课程名称'"
              >{{ node.label }}</el-checkbox
            >
          </template>
        </el-tree>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cencal">取 消</el-button>
        <el-button class="bgc-bv" @click="resetHeadOk">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      width="800px"
      title="提示"
      :visible.sync="paraIsPopDialog"
      top="2%"
    >
      <p style="font-size: 1rem; padding: 15px 1rem">
        提示：根据监管要求每学时弹题<span
          style="color: #f56c6c; font-size: 1.25rem"
          >{{ popNum }}</span
        >次，课件弹题配置题目数量少于课件学时*<span
          style="color: #f56c6c; font-size: 1.25rem"
          >{{ popNum }}</span
        >道(四舍五入)，不合格课件列表如下:
      </p>
      <div class="ovy-a">
        <el-table
          ref="multipleTable"
          :data="retData"
          height="500px"
          size="small"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="tableHeader"
          stripe
        >
          <el-table-column
            label="序号"
            align="center"
            type="index"
            fixed
            :index="indexMethod"
          />
          <el-table-column
            label="课件名称"
            align="center"
            show-overflow-tooltip
            prop="courseName"
            min-width="150px"
          />
          <el-table-column
            label="应配弹题题目(道)"
            align="center"
            show-overflow-tooltip
            prop="minPopNum"
            min-width="150px"
          />
          <el-table-column
            label="实际配弹题题目(道)"
            align="center"
            show-overflow-tooltip
            prop="currPopNum"
            min-width="150px"
          />
          <Empty slot="empty" />
        </el-table>
      </div>
    </el-dialog>
  </div>
</template>
<script>
const className = "eduCertService";
import QRCode from "qrcodejs2"; // 引入qrcode
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import tree from "@/components/treePopup";
import List from "@/mixins/List";
import moment from "moment";
import pdf from "pdfobject";
import { mapGetters } from "vuex";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "classListCtr",
  components: {
    Empty,
    PageNum,
    tree,
  },
  mixins: [List],
  data() {
    return {
      paraIsPopDialog: false, //弹题错误数据
      // dialogVisible: false,
      areatreeList: [],
      ClassstatusList: [],
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      projectName: "",
      projectCode: "",
      ruleForm: {
        areaId: "",
        projectUser: "",
        projectUserPhone: "",
        Classstatus: "",
        opentime: "",
        endTime: "",
        Trainingtype: "",
      },
      params: {},
      projectNum: "", //班级总数：
      projectPeopleNum: "", //班级总人次：
      flag: false,
      // 自定义表头
      tableHeadDialog: false, //
      treeData: [
        {
          label: "行政区划", //表头名称
          propName: "areaNamePath", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "150", // 列的宽
          order: 1,
          id: "1",
          checked: true,
        },
        {
          label: "培训类型", //表头名称
          propName: "trainTypeNamePath", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "150", // 列的宽
          order: 2,
          id: "2",
          checked: true,
        },
        {
          label: "行业类型", //表头名称
          propName: "industryNamePath", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "160", // 列的宽
          order: 3,
          id: "3",
          checked: true,
        },
        {
          label: "岗位类型", //表头名称
          propName: "postName", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "160", // 列的宽
          order: 4,
          id: "4",
          checked: true,
        },
        {
          label: "职业/工种", //表头名称
          propName: "occupationNamePath", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "120", // 列的宽
          order: 5,
          id: "5",
          checked: true,
        },
        {
          label: "培训等级", //表头名称
          propName: "trainLevelName", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "120", // 列的宽
          order: 6,
          id: "6",
          checked: true,
        },
        {
          label: "负责人", //表头名称
          propName: "projectUser", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "120", // 列的宽
          order: 7,
          id: "7",
          checked: true,
        },
        {
          label: "负责人电话", //表头名称
          propName: "projectUserPhone", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "100", // 列的宽
          order: 8,
          id: "8",
          checked: true,
        },
        {
          label: "开始时间", //表头名称
          propName: "startDate", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "time", //渲染类型
          timeType: "momentDate", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "120", // 列的宽
          order: 9,
          id: "9",
          checked: true,
        },
        {
          label: "结束时间", //表头名称
          propName: "endDate", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "time", //渲染类型
          timeType: "momentDate", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "120", // 列的宽
          order: 10,
          id: "10",
          checked: true,
        },
        {
          label: "报名截止", //表头名称
          propName: "signEndDate", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "time", //渲染类型
          timeType: "momentDate", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "120", // 列的宽
          order: 11,
          id: "11",
          checked: true,
        },
        {
          label: "班级状态", //表头名称
          propName: "auditState", //后台对应字段名称
          textAlign: "center", //文字对齐方式e
          renderType: "status", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "AUDITSTATE", //字典对应key
          width: "120", // 列的宽
          order: 12,
          id: "12",
          checked: true,
        },
        {
          label: "班级总人数", //表头名称
          propName: "projectPeopleNum", //后台对应字段名称
          textAlign: "right", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "140", // 列的宽
          order: 13,
          id: "13",
          checked: true,
        },
        {
          label: "课程总人次", //表头名称
          propName: "projectCourseNum", //后台对应字段名称
          textAlign: "right", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "140", // 列的宽
          order: 14,
          id: "14",
          checked: true,
        },
      ],
      tableHaed: [],
      retData: [],
      popNum: "",
    };
  },
  created() {
    this.getTableHeight();
    this.getareatree();
    this.getclassstatusList();
    this.getTableHead();
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
      userJson: "getUser",
    }),
  },
  mounted() {},
  methods: {
    processDate() {
      let self = this;
      return {
        disabledDate(time) {
          if (self.extensionForm.originalEndDate) {
            return (
              new Date(self.extensionForm.originalEndDate).getTime() >=
                time.getTime() ||
              time.getTime() >= self.extensionForm.originalEndDate
            );
          } else {
            return time.getTime() >= self.extensionForm.originalEndDate; //开始时间不选时，结束时间最大值小于等于当天
          }
        },
      };
    },
    // 根据字段转换页面展示的内容
    getValue(el, row) {
      // 状态
      if (el.renderType == "status") {
        if (el.propName == "auditState") {
          if (
            row.auditState == "10" ||
            row.auditState == "20" ||
            row.auditState == "40"
          ) {
            return this.$setDictionary("AUDITSTATE", row.auditState) == "未提审"
              ? "未申请"
              : this.$setDictionary("AUDITSTATE", row.auditState) ==
                "审核未通过"
              ? "未通过"
              : this.$setDictionary("AUDITSTATE", row.auditState);
          } else if (
            row.auditState == "30" &&
            row.projectStudyTimeState != "10"
          ) {
            return this.$setDictionary(
              "PROJECTSTATE",
              row.projectStudyTimeState
            ) == "审核未通过"
              ? "未通过"
              : this.$setDictionary("PROJECTSTATE", row.projectStudyTimeState);
          } else if (row.auditState == "30") {
            return this.$setDictionary("AUDITSTATE", row.auditState);
          }
          if (row.auditState == "25") {
            return "运营审核通过";
          } else {
            return this.$setDictionary(el.dictionaries, row[el.propName]);
          }
        } else {
          return this.$setDictionary(el.dictionaries, row[el.propName]);
        }
      } else if (el.renderType == "time") {
        // 时间
        if (el.timeType == "moment") {
          return row[el.propName]
            ? this.$moment(row[el.propName]).format("YYYY-MM-DD HH:mm:ss")
            : "--";
        } else if (el.timeType == "momentDate") {
          return row[el.propName]
            ? this.$moment(row[el.propName]).format("YYYY-MM-DD")
            : "--";
        } else if (el.timeType == "custom") {
          return this.getTime(row[el.propName]);
        } else {
          return row[el.propName];
        }
      } else {
        // 其他
        return row[el.propName] || "--";
      }
    },
    // 自定义确定
    resetHeadOk() {
      let list = [];
      let saveData = [];
      this.treeDataCopy.forEach((el, index) => {
        saveData.push({ id: el.id, order: index, checked: el.checked });
        if (el.checked) {
          list.push(el);
        }
      });
      this.tableHaed = list;
      this.tableHeadDialog = false;
      this.$post("/sys/admin/savePageConfig", {
        configInfo: JSON.stringify(saveData),
        pageName: "classListCtr",
      }).then((res) => {
        // ;
        this.getTableHead();
      });
    },
    open() {
      this.tableHeadDialog = true;
      this.handle();
    },
    cencal() {
      this.tableHeadDialog = false;
    },
    getTableHead() {
      this.$post("/sys/admin/findAdminConfig", {
        pageName: "classListCtr",
      })
        .then((res) => {
          if (res.status == 0) {
            const data = res.data || {};
            let list = [];
            if (data.configInfo && data.configInfo != "") {
              list = JSON.parse(data.configInfo);
            }
            if (list.length) {
              list.map((el) => {
                this.treeData.forEach((it, index) => {
                  if (el.id == it.id) {
                    it.checked = el.checked;
                    it.order = el.order;
                  }
                });
              });
              this.treeData.sort((a, b) => {
                return a.order - b.order;
              });
              this.handle();
            } else {
              this.handle();
            }
          } else {
            this.handle();
            // this.tableHaed = [...this.treeData];
          }
        })
        .catch((err) => {
          this.tableHaed = [...this.treeData];
        });
    },
    handle() {
      this.tableHaed = [];
      this.treeDataCopy = [];
      this.treeData.forEach((el) => {
        this.treeDataCopy.push(Object.assign({}, el));
      });
      this.treeDataCopy.forEach((el) => {
        if (el.checked) {
          this.tableHaed.push(el);
        }
      });
      this.showtable = true;
    },
    // 判断拖拽的位置
    allowDrop(draggingNode, dropNode, type) {
      return type !== "inner";
    },
    // 节点是否可以拖拽
    allowDrag(draggingNode) {
      if (draggingNode.label == "课程名称") {
        return false;
      }
      return true;
    },
    handleCheckChange(val, data) {
      // console.log(data);
    },
    //获取班级总数和班级总人次
    getTotal(params) {
      this.$post("/biz/project/queryHospitalProjectTotal", params, false)
        .then((ret) => {
          this.projectNum = ret.data.projectNum;
          this.projectPeopleNum = ret.data.projectPeopleNum;
        })
        .catch((err) => {
          return;
        });
    },
    childBack(params) {
      this.params = { ...params };
    },
    clearParams() {
      this.params = {};
    },
    getData(pageNum = 1) {
      console.log(this.apiData);
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.projectName) {
        params.projectName = this.projectName.trim();
      }
      if (this.projectCode) {
        params.projectCode = this.projectCode.trim();
      }
      if (this.ruleForm.areaId) {
        params.areaId = this.ruleForm.areaId;
      }
      if (this.ruleForm.projectUser) {
        params.projectUser = this.ruleForm.projectUser;
      }
      if (this.ruleForm.projectUserPhone) {
        params.projectUserPhone = this.ruleForm.projectUserPhone;
      }
      if (this.ruleForm.Classstatus) {
        params.projectStudyTimeState = this.ruleForm.Classstatus;
      }
      if (this.ruleForm.opentime) {
        params.startDateLong = this.ruleForm.opentime[0];
        params.endDateLong = this.ruleForm.opentime[1];
      }
      if (this.ruleForm.endTime) {
        params.endDateStartLong = this.ruleForm.endTime[0];
        params.endDateEndLong = this.ruleForm.endTime[1];
      }
      if (this.params.trainFormId) {
        params.trainTypeId = this.params.trainFormId;
      }
      if (this.params.levelFormId) {
        params.trainLevelId = this.params.levelFormId;
      }
      if (this.params.occFormId) {
        params.occupationId = this.params.occFormId;
      }
      if (this.params.postFormId) {
        params.postId = this.params.postFormId;
      }
      if (this.params.industryFormId) {
        params.industryId = this.params.industryFormId;
      }

      console.log("获取列表数据: ", params);
      this.doFetch(
        {
          url: "/biz/project/hospital/pageList",
          params,
          pageNum,
        },
        true
      );
      this.getTotal(params);
    },
    //获取行政区划
    getareatree() {
      this.$post("/sys/area/tree").then((ret) => {
        this.areatreeList = ret.data;
      });
    },
    getclassstatusList() {
      const classstatuslist = this.$setDictionary("PROJECTSTATE", "list");
      const list = [];
      for (const key in classstatuslist) {
        list.push({
          value: key,
          label: classstatuslist[key],
        });
      }
      this.ClassstatusList = list;
    },
    //删除
    deleteproject(projectId) {
      this.doDel({
        url: "/biz/project/delete",
        msg: "你确定要删除该班级吗？",
        ps: {
          type: "post",
          data: { projectId },
        },
      });
    },
    //详情
    seeDetails(row) {
      sessionStorage.setItem("projectId", row.projectId);
      sessionStorage.setItem("areaId", row.areaId);
      sessionStorage.setItem("trainTypeNamePath", row.trainTypeNamePath);
      sessionStorage.setItem("auditState", row.auditState);
      sessionStorage.setItem("projectName", row.projectName);
      sessionStorage.setItem("courseCount", row.courseCount);
      sessionStorage.setItem("trainTypeId", row.trainTypeId);
      sessionStorage.setItem("projectSource", row.projectSource);
      sessionStorage.setItem("cClessonNum", row.lessonNum);
      sessionStorage.setItem("signEndDateLong", row.signEndDate);

      this.$router.push({
        path: "/web/ClassdetailsListBj",
        query: {
          active: "first",
        },
      });
    },
    //班级列表
    jumpclassList(row) {
      sessionStorage.setItem("projectId", row.projectId);
      sessionStorage.setItem("areaId", row.areaId);
      sessionStorage.setItem("trainTypeNamePath", row.trainTypeNamePath);
      sessionStorage.setItem("auditState", row.auditState);
      sessionStorage.setItem("projectName", row.projectName);
      sessionStorage.setItem("courseCount", row.courseCount);
      sessionStorage.setItem("trainTypeId", row.trainTypeId);
      sessionStorage.setItem("projectSource", row.projectSource);
      sessionStorage.setItem("cClessonNum", row.lessonNum);
      sessionStorage.setItem("signEndDateLong", row.signEndDate);
      this.$router.push({
        path: "/web/ClassdetailsListBj",
        query: {
          active: "second",
        },
      });
    },
    //跳转学员列表
    jumpStudentList(row) {
      ;
      sessionStorage.setItem("projectId", row.projectId);
      sessionStorage.setItem("areaId", row.areaId);
      sessionStorage.setItem("trainTypeNamePath", row.trainTypeNamePath);
      sessionStorage.setItem("auditState", row.auditState);
      sessionStorage.setItem("projectName", row.projectName);
      sessionStorage.setItem("courseCount", row.courseCount);
      sessionStorage.setItem("trainTypeId", row.trainTypeId);
      sessionStorage.setItem("projectSource", row.projectSource);
      sessionStorage.setItem("cClessonNum", row.lessonNum);
      sessionStorage.setItem("signEndDateLong", row.signEndDate);
      this.$router.push({
        path: "/web/ClassdetailsListBj",
        query: {
          active: "three",
        },
      });
    },
    //开班申请
    keeprecord(projectId, projectSource, courseCount,completePaper, row) {
      this.projectId = projectId;
      console.log(Number(courseCount));
      if (Number(courseCount) < 1) {
        this.$confirm("你的班级还未配置课程，请先为班级配课", "提示", {
          confirmButtonText: "班级配课",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.jumpclassList(row);
          })
          .catch(() => {
            return;
          });
      } else {
        if(!completePaper) {
           this.$confirm(
          "尚未配置结业考试,是否继续",
          "提示",
          {
            confirmButtonText: "是",
            cancelButtonText: "否",
            type: "warning",
          }
        )
          .then(() => {

            this.$confirm(
          "你确定提交开班申请吗？申请通过后班级信息及班级课程列表将不允许修改",
          "开班申请",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(() => {
            this.$post("/biz/project/affirm", { projectId })
              .then((ret) => {
                if (ret.status == 0) {
                  this.$post("/run/project/paper/rule/composePaper", {
                    projectId,
                  }).then((res1) => {
                    if (res1.status == 0) {
                      this.$message({
                        message: "开班申请成功",
                        type: "success",
                      });
                      this.getData(-1);
                    }
                  });
                }
              })
              .catch((err) => {
                return;
              });
          })
          .catch(() => {
            return;
          });
          })
          .catch(() => {
            return;
          });
        } else {
           this.$confirm(
          "你确定提交开班申请吗？申请通过后班级信息及班级课程列表将不允许修改",
          "开班申请",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(() => {
            this.$post("/biz/project/affirm", { projectId })
              .then((ret) => {
                if (ret.status == 0) {
                  this.$post("/run/project/paper/rule/composePaper", {
                    projectId,
                  }).then((res1) => {
                    if (res1.status == 0) {
                      this.$message({
                        message: "开班申请成功",
                        type: "success",
                      });
                      this.getData(-1);
                    }
                  });
                }
              })
              .catch((err) => {
                return;
              });
          })
          .catch(() => {
            return;
          });
        }
       
      }
    },
    // 新增 编辑
    addTestPaper() {
      this.$router.push({
        path: "/web/addClassBj",
        query: {
          projectId: null,
          stu: "add",
        },
      });
    },
    // 高级搜索弹窗
    search() {
      this.flag = !this.flag;
      this.params = {};
      this.getTableHeight();
    },
    //清空搜索条件
    resetForm() {
      this.params = {};
      this.ruleForm = {};
      this.projectCode = "";
      this.projectName = "";
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -=
          this.flag == false ? 80 + 0.675 * 16 + 6 : 170 + 0.675 * 16 + 6;
      }
      if (page) {
        tHeight -= 24;
      }
      this.tableHeight = tHeight + 4;
      // // 40为thead高 | trHeight默认45为带按钮的tbody的tr高
      // this.pageSize = Math.ceil((tHeight -40) / trHeight);
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    // 监听缓存后调用接口
    $route: {
      handler: function (route) {
        if (route.params.refresh) {
          if (route.params.isAdd) {
            this.projectName = "";
            this.projectCode = "";
            this.ruleForm = {
              areaId: "",
              projectUser: "",
              projectUserPhone: "",
              Classstatus: "",
              opentime: "",
              endTime: "",
              Trainingtype: "",
            };
            this.params = {};
            this.getData(-1);
          } else {
            this.getData();
          }
        } else if (sessionStorage.getItem("refresh") != 0) {
          sessionStorage.setItem("refresh", 0);
          this.projectName = "";
          this.projectCode = "";
          this.ruleForm = {
            areaId: "",
            projectUser: "",
            projectUserPhone: "",
            Classstatus: "",
            opentime: "",
            endTime: "",
            Trainingtype: "",
          };
          this.params = {};
          this.getData(-1);
        } else {
          this.getData(-1);
        }
      },
      deep: true,
    },
  },
};
</script>
<style lang="less">
.el-table__body-wrapper {
  z-index: 2;
}
</style>
<style lang="less" scoped>
.el-checkbox {
  display: flex;
  white-space: pre-wrap !important;
  text-align: justify;
}
.searchbox > div .el-input {
  flex: 1;
}
.projectUser {
  min-width: 6rem !important;
}
.qrcode {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 300px;
    height: 300px;
    background-color: #999; //设置白色背景色
  }
}
.leftData {
  display: flex;
  flex-direction: column;
  img {
    width: 300px;
    height: 300px;
  }
  span {
    display: flex;
    width: 300px;
  }
}

.refreshcode {
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 1rem; */
  padding-top: 1rem;
}
.searchBox {
  padding: 0;
  .el-icon-arrow-down:before {
    content: "\e6df" !important;
  }
}
.downloadStyle {
  background-color: #f0f5fb;
  padding: 20px 0;
  margin-bottom: 30px;
  overflow: hidden;
}
.docsDialog {
  .el-form-item {
    width: 44%;
    float: left;
    margin-bottom: 0.5rem;
    margin: 10px 3%;
  }
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 23px;
  width: 60px;
  display: block;
}
.searchboxItems {
  display: flex;
  align-items: center;
  .itemLabel {
    min-width: 5rem;
    text-align: right;
    font-size: 14px;
    padding: 0 0.5rem;
  }
}
.operationControlCurr {
  > div {
    width: 100%;
    justify-content: space-between;
  }
  .itemLabel {
    min-width: 5rem;
    text-align: right;
    font-size: 14px;
    padding: 0 0.5rem;
  }
}
.el-divider--horizontal {
  margin: 13px 0;
}
.dailyRemind {
  margin-left: 30px;
  margin-top: 10px;
  .dailyRemind-1 {
    margin-bottom: 20px;
    p {
      margin-top: 10px;
    }
  }
  .dailyRemind-2 {
    p {
      margin-top: 10px;
    }
  }
}
.el-dialogs {
  .el-cascader {
    width: 100%;
  }
}
</style>
<style lang="less" scope>
.upload-btn {
  width: 5rem;
  height: 28px;
  .el-upload {
    width: 5rem;
    height: 28px !important;
    border: none !important;
  }
}
</style>
